<template>
  <layout name="NoColumns">
    <authorize-routed-with-fallback :route="$route">
      <router-view />
    </authorize-routed-with-fallback>
  </layout>
</template>

<script>
import AuthorizeRoutedWithFallback from '@/components/core/AuthorizeRoutedWithFallback'

export default {
  name: 'StreamsIndex',
  components: { AuthorizeRoutedWithFallback }
}
</script>
